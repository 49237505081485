import { Grid } from "@material-ui/core";
import logo from '../images/epic-logo.png';
import hero from '../images/login-hero.jpg';
import "../css/main.css";
import * as data from '../Data'
import { removeCookie, setCookie } from 'typescript-cookie'
import { Member } from "../interfaces/Member";
import MemberLoginCredentials from "../interfaces/MemberLoginCredentials";
import { MouseEvent } from "react";
import { Cookies } from "typescript-cookie";
import { Link } from "react-router-dom";

const forgotPassword = (e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>) => {
    e.preventDefault();
    console.log("forgot password");
}

const loginWithPassword = async () => {
    console.log("Login attempt");
    const loginButton = document.getElementById('login-button') as HTMLButtonElement;
    loginButton.disabled = true;
    loginButton.innerText = 'Signing in, please wait...';
    removeErrorMessage();

    const email = (document.getElementById('email') as HTMLInputElement).value;
    const password = (document.getElementById('password') as HTMLInputElement).value;

    const memberLoginCredentials: MemberLoginCredentials = {
        username: email,
        password: password
    }

    const memberAuth = await data.loginWithPassword(
        memberLoginCredentials,
        (member: Member) => {
            if (member) {
                if (member.passwordIsTemp == 'Yes') {
                    window.location.href = "/update-password";
                } else {
                    window.location.href = "/";
                }
            } else {
                addErrorMessage('The email and password are incorrect. Please try again.');
                loginButton.disabled = false;
                loginButton.innerText = 'Login';    
            }
        },
        (error) => {
            console.log(error);
            addErrorMessage('There was a problem signing in. Please try again or contact member services for technical support.');
            loginButton.disabled = false;
            loginButton.innerText = 'Login';
        }
    )
}

const removeErrorMessage = () => {
    let errorMessage = (document.getElementById('error-message') as HTMLInputElement);
    errorMessage.classList.add("hidden");
    errorMessage.innerHTML = '';
}

const addErrorMessage = (message: string) => {
    let errorMessage = (document.getElementById('error-message') as HTMLInputElement);
    errorMessage.classList.remove("hidden");
    errorMessage.innerText = message;
}

export function LoginPage() {

    removeCookie('owner');

    return (
        <div>
            <Grid container>
                <div style={{
                    backgroundImage: `url(${hero})`,
                    minHeight: '100vh',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                    width: '100%',
                }}>
                </div>

                <div className="modal">
                    <div className="modal-content">
                        <div style={{ width: '100%', textAlign: 'center' }}><img style={{ width: "75%", margin: '20px 10px 10px 10px' }} src={ logo } alt='Epic Logo' /></div>
                        <div className="login-form">
                            <table cellSpacing="8">
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'right' }}>Email:</td>
                                        <td><input id="email" className="login-input" type="text" size={36}></input></td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'right' }}>Password:</td>
                                        <td><input id="password" className="login-input" type="password" size={36}></input></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button id="login-button" onClick={loginWithPassword} style={{ cursor: 'pointer', margin: '10px 0px 30px 0px', padding: '10px 30px', backgroundColor: '#22a845', border: '0px solid', borderRadius: '5px', color: 'white' }} >Login</button>
                        </div>
                        <div id="error-message" className="login-error hidden"></div>
                        <div style={{ textAlign: 'center' }}>
                            <a href="/request-reset-password" style={{ color: 'blue', fontWeight: 'normal' }}>Forgot your password?</a>
                        </div>
                    </div>
                </div>
            </Grid>
        </div>
    )
}