import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";
import image1 from '../images/image_1.jpg';
import image2 from '../images/image_2.jpg';
import image3 from '../images/image_3.jpg';

export function ShareTheLove() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
                <div>
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{ width: '100%', margin: '0px' }}>
                                <img src={banner} style={{ width: '100%' }} alt='beach image' />
                            </div>
                            <div style={{
                                fontSize: '2.5em',
                                fontFamily: 'Satisfy',
                                fontWeight: 'bold',
                                width: '100%',
                                textAlign: 'center',
                                margin: '10px'
                            }}>
                                Share the Love
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginBottom: '60px' }}>
                        <Grid item xs={12}>
                            <div style={{
                                margin: 'auto',
                                width: '50%',
                                lineHeight: '130%',
                                fontSize: '1.15em',
                            }}>
                                <div style={{ marginTop: '20px' }}>Share Epic savings with family and friends!</div>
                                <div style={{ marginTop: '20px' }}>One of your Epic Experiences benefits is the ability to share your travel savings with family and friends.</div>
                                <div style={{ marginTop: '20px' }}>Sharing with your loved ones is easy. Simply log on to Epic Experiences. Click on the Epic Travel Savings menu item and the invitation appears. When they respond to your invitation family and friends will receive simple instructions on how to create a FREE account for one year.</div>
                                <div style={{ marginTop: '20px' }}>Epic members have an unlimited number of invitations!</div>
                                <div style={{ marginTop: '20px' }}>The best part is, when your family and friends use your benefits, you receive Reward Credits for their reservations!</div>
                                <div style={{ marginTop: '20px' }}>Your family and friends save 50% of the amount you would save on cars, hotels, and activities. The remaining 50% of the amount you would save is returned to you in the form of Reward Credits.</div>
                                <div style={{ marginTop: '20px' }}>So go on, share the love. Be an Epic friend and receive rewards for doing so!</div>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }

            {owner && <Footer />}
        </div>
    )
}