import { Grid } from "@material-ui/core";
import logo from '../images/epic-logo.png';
import hero from '../images/login-hero.jpg';
import "../css/main.css";
import * as data from '../Data'
import { removeCookie } from 'typescript-cookie'

const resetPassword = async () => {
    const resetPasswordButton = document.getElementById('reset-password-button') as HTMLButtonElement;
    resetPasswordButton.disabled = true;
    resetPasswordButton.innerText = 'Please wait...';
    removeErrorMessage();

    const email = (document.getElementById('email') as HTMLInputElement).value;

    const memberAuth = await data.requestPasswordReset(
        email,
        () => {
            console.log("Password was reset");
            resetPasswordButton.innerText = 'Please check your email for a password reset link.';        
        },
        (error) => {
            console.log(error);
            addErrorMessage('There was a problem resetting your password. Please try again or contact member services for technical support.');
            resetPasswordButton.disabled = false;
            resetPasswordButton.innerText = 'Login';
        }
    )
}

const removeErrorMessage = () => {
    let errorMessage = (document.getElementById('error-message') as HTMLInputElement);
    errorMessage.classList.add("hidden");
    errorMessage.innerHTML = '';
}

const addErrorMessage = (message: string) => {
    let errorMessage = (document.getElementById('error-message') as HTMLInputElement);
    errorMessage.classList.remove("hidden");
    errorMessage.innerText = message;
}

export function RequestResetPassword() {

    removeCookie('owner');

    return (
        <div>
            <Grid container>
                <div style={{
                    backgroundImage: `url(${hero})`,
                    minHeight: '100vh',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                    width: '100%',
                }}>
                </div>

                <div className="modal">
                    <div className="modal-content">
                        <div style={{ width: '100%', textAlign: 'center' }}><img style={{ width: "75%", margin: '20px 10px 10px 10px' }} src={ logo } alt='Epic Logo' /></div>
                        <div className="login-form">
                            <p>Please enter your email below to reset your password.</p>
                            <table cellSpacing="8">
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'right' }}>Email:</td>
                                        <td><input id="email" className="login-input" type="text" size={36}></input></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button id="reset-password-button" onClick={ resetPassword } style={{ cursor: 'pointer', margin: '10px 0px 30px 0px', padding: '10px 30px', backgroundColor: '#22a845', border: '0px solid', borderRadius: '5px', color: 'white' }} >Reset Password</button>
                        </div>
                        <div id="error-message" className="login-error hidden"></div>
                    </div>
                </div>
            </Grid>
        </div>
    )
}