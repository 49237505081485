import React from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { LoginPage } from './pages/LoginPage';
import { HomePage } from './pages/HomePage';
import { PlanVacation } from './pages/PlanVacation';
import { EpicAdvantagePoints } from './pages/EpicAdvantagePoints';
import { MemberResources } from './pages/MemberResources';
import { ExplorerTier } from './pages/ExplorerTier';
import { VoyagerTier } from './pages/VoyagerTier';
import { WayfarerTier } from './pages/WayfarerTier';
import { TravelerTier } from './pages/TravelerTier';
import { HiltonHeadIslandPrivileges } from './pages/HiltonHeadIslandPrivileges';
import { EpicPointsBasics } from './pages/EpicPointsBasics';
import { EpicExperiences } from './pages/EpicExperiences';
import { MembershipDues } from './pages/MembershipDues';
import { Contacts } from './pages/Contacts';
import { MemberProfile } from './pages/MemberProfile';
import { RequestResetPassword } from './pages/RequestResetPassword';
import { EmbarcHomePage } from './pages/EmbarcHomePage';
import { UpdatePassword } from './pages/UpdatePassword';
import { MemberRewardCredits } from './pages/MemberRewardCredits';
import { ShareTheLove } from './pages/ShareTheLove';
import { ResetPassword } from './pages/ResetPassword';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/embarc" element={<EmbarcHomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/plan-my-vacation" element={<PlanVacation />} />
        <Route path="/epic-advantage-points" element={<EpicAdvantagePoints />} />
        <Route path="/member-resources" element={<MemberResources />} />
        <Route path="/explorer-tier" element={<ExplorerTier />} />
        <Route path="/voyager-tier" element={<VoyagerTier />} />
        <Route path="/wayfarer-tier" element={<WayfarerTier />} />
        <Route path="/traveler-tier" element={<TravelerTier />} />
        <Route path="/hilton-head-island-privileges" element={<HiltonHeadIslandPrivileges />} />
        <Route path="/epic-points-basics" element={<EpicPointsBasics />} />
        <Route path="/epic-experiences" element={<EpicExperiences />} />
        <Route path="/membership-dues" element={<MembershipDues />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/member-profile" element={<MemberProfile />} />
        <Route path="/share-the-love" element={<ShareTheLove />} />
        <Route path="/member-reward-credits" element={<MemberRewardCredits />} />
        <Route path="/request-reset-password" element={<RequestResetPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/update-password" element={<UpdatePassword />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
