import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";
import image1 from '../images/image_1.jpg';
import image2 from '../images/image_2.jpg';
import image3 from '../images/image_3.jpg';

export function MemberRewardCredits() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', margin: '0px' }}>
                            <img src={banner} style={{ width: '100%' }} alt='beach image' />
                        </div>
                        <div style={{
                            fontSize: '2.5em',
                            fontFamily: 'Satisfy',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            margin: '10px'
                        }}>
                            Member Reward Credits
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: '60px' }}>
                    <Grid item xs={12}>
                        <div style={{
                            margin: 'auto',
                            width: '50%',
                            lineHeight: '130%',
                            fontSize: '1.15em',
                        }}>
                            <div style={{ marginTop: '20px' }}>In addition to exclusive year-round member pricing on travel-related services, you earn Rewards Credits for every dollar spent on qualified Epic Experiences.</div>
                            <div style={{ marginTop: '20px' }}>Epic Reward Credits accumulate and can be used towards future travel-related purchases such as hotel stays and car rentals.</div>
                            <div style={{ marginTop: '20px' }}>The higher your membership tier the more opportunity you have to earn and redeem your Epic Reward Credits. In addition to travel, Reward Credits accrue when you enjoy activities, wine club purchases and even when you shop for your favorite brands.</div>
                            <div style={{ marginTop: '20px' }}>Epic Reward Credits are easy to redeem. On the search bar for the travel service you are purchasing there will be a choice to see your cost in cash or Epic Reward Credits. If you do not have enough Reward Credits to fund your entire purchase the system will apply your Reward Credits balance to the cost and tell you how many additional dollars you owe.</div>
                       </div>
                    </Grid>
                </Grid>
            </div>
            }

            {owner && <Footer />}
        </div>
    )
}