import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner_5.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";

export function MemberProfile() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', margin: '0px' }}>
                            <img src={banner} style={{ width: '100%' }} alt='beach image' />
                        </div>
                        <div style={{
                            fontSize: '2.5em',
                            fontFamily: 'Satisfy',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            margin: '10px'
                        }}>
                            Member Profile
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: '60px' }}>
                    <Grid item xs={12}>
                        <div style={{
                            margin: 'auto',
                            width: '50%',
                            lineHeight: '130%',
                            fontSize: '1.15em',
                            textAlign: 'justify'
                        }}>
                            <Grid container style={{ marginBottom: '60px' }}>
                                <Grid item xs={6}>
                                    <div className='tier-sub-label'>{ owner.firstName } { owner.lastName }</div>
                                    <div style={{ marginTop: '5px'}}>
                                        { owner.address }
                                        <br />{ owner.city }, { owner.state } { owner.zipcode }
                                        <br />Home: { owner.homePhone }
                                        <br />Mobile: { owner.mobilePhone }
                                        <br />Email: { owner.email }
                                    </div>
                                </Grid>
                                <Grid item style={{ paddingLeft: '40px' }} xs={6}>
                                    <div className='tier-sub-label'>Epic Membership</div>
                                    <div style={{ marginTop: '5px'}}>
                                        <table cellSpacing={15}>
                                            <tr>
                                                <td>Membership ID:</td>
                                                <td>{ owner.memberId }</td>
                                            </tr>
                                            <tr>
                                                <td>Membership Tier:</td>
                                                <td>{ owner.membershipTier }</td>
                                            </tr>
                                            <tr>
                                                <td>Epic Advantage Points<br />Available for Purchase:</td>
                                                <td >{ (owner.currentYearEpicAdvPointsAvail).toLocaleString()}</td>
                                            </tr>
                                        </table>
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </Grid>
                </Grid>
            </div>
            }

            {owner && <Footer />}
        </div>
    )
}