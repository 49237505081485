import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner_4.jpg";
import * as data from '../Data'
import { Cookies, getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";
import image1 from '../images/image_1.jpg';
import image2 from '../images/image_5.jpg';
import image3 from '../images/image_3.jpg';
import logo from '../images/epic-logo.png';

const removeModal = () => {
    const partnerModal = document.getElementById('partner-modal') || undefined;

    if (partnerModal) {
        partnerModal.style.display = 'none';

    }
}

const visitPartner = (url: string) => {
    const partnerModal = document.getElementById('partner-modal') || undefined;

    if (partnerModal) {
        partnerModal.style.display = 'block';

    }

    const partnerLink = document.getElementById('partner-link') as HTMLAnchorElement || undefined;

    if (partnerLink) {
        partnerLink.href = url;        
    } else {
        console.log('no partnerLink');
    }

}

export function PlanVacation() {
    const [owner, setOwner] = useState<Member>();
    const [sorLink, setSorLink] = useState('');
    const [rciLink, setRciLink] = useState('');

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
            const member = JSON.parse(ownerCookie) as Member;
            setRciLink(member.rciLink);

            const getSorLink = async () => {
                const memberWithSorLink = await data.partnerLogin(
                    member,
                    (member) => {
                        setSorLink(member.sorLink);
                    },
                    (error) => {
                        console.log(error);
                    })
            }

            getSorLink();

        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
                <div>
                    <div id='partner-modal' style={{ display: 'none' }}>
                        <div style={{ position: 'fixed', top: '0', left: '0', backgroundColor: 'black', opacity: '.6', zIndex: '1', width: '100vw', height: '100vh' }}>
                        </div>
                        <div className="modal-solid">
                            <div className="modal-content-solid">
                                <div style={{ width: '100%', textAlign: 'center' }}><img style={{ width: "30%", margin: '30px 10px 10px 10px' }} src={logo} alt='Epic Logo' /></div>
                                <div style={{ margin: '30px', fontSize: '1.1em' }}>
                                    You are about to visit one of our travel partner's website. The partner website will open in a new tab in your browser. To return to the Member Portal, please click on this tab or close the partner website tab.
                                </div>
                                <div style={{ width: '100%', textAlign: 'center' }}>
                                    <a id='partner-link' onClick={ removeModal } href='#' target='_blank'><button style={{
                                        margin: '20px 0px',
                                        padding: '10px 20px',
                                        backgroundColor: '#031d42',
                                        width: '50%',
                                        border: '0px solid',
                                        borderRadius: '8px',
                                        color: 'white',
                                        fontFamily: 'Assistant',
                                        fontWeight: '500',
                                        fontSize: '1.2em'
                                    }} >Continue</button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Grid container>
                        <Grid item xs={12}>
                            <div style={{ width: '100%', margin: '0px' }}>
                                <img src={banner} style={{ width: '100%' }} alt='beach image' />
                            </div>
                            <div style={{
                                fontSize: '2.5em',
                                fontFamily: 'Satisfy',
                                fontWeight: 'bold',
                                width: '100%',
                                textAlign: 'center',
                                margin: '10px'
                            }}>
                                Plan My Vacation
                            </div>
                        </Grid>
                    </Grid>

                    <Grid container style={{ marginBottom: '60px' }}>
                        <Grid item xs={4} style={{ padding: '0px 40px', textAlign: 'center', width: '100%' }}>
                            <img src={image1} alt='vacation' style={{ width: '100%', borderRadius: '6px' }} />
                            <button className="action-button" onClick={() => visitPartner(rciLink)}>Book Epic Vacations & Epic Escapes</button>
                            <div style={{
                                fontSize: '1.1em',
                                fontFamily: 'Assistant',
                                fontWeight: '500',
                                width: '95%',
                                lineHeight: '150%',
                                textAlign: 'justify',
                                paddingLeft: '2.5%'
                            }}>
                                <p>Travel with your points!  Enjoy condominium-style stays at over 4,300 destinations worldwide, including Hilton Head Island, the Pacific Coast, Rocky Mountains, Gulf States, Caribbean, Greece, Mexico, and Europe. Start your adventure here.</p>
                                <p><b>Epic Escapes:</b> Ideal for spontaneous travelers, book weeklong condominium vacations up to 30 days prior to arrival for 10,000 points or less!</p>
                                <p><b>Epic Vacations:</b> Plan ahead with reservations up to 24 months in advance!</p>
                                <p>Need more points? For grander accommodations during high-demand period, purchase <a href="./epic-advantage-points">Epic Advantage Points</a> at a low member price to expand your vacation options.</p>
                            </div>
                        </Grid>
                        <Grid item xs={4} style={{ padding: '0px 40px', textAlign: 'center', width: '100%' }}>
                            <img src={image3} alt='vacation' style={{ width: '100%', borderRadius: '6px' }} />
                            <button className="action-button" onClick={() => visitPartner(sorLink)}>Book Epic Experiences</button>
                            <div style={{
                                fontSize: '1.1em',
                                fontFamily: 'Assistant',
                                fontWeight: '500',
                                width: '95%',
                                lineHeight: '150%',
                                textAlign: 'justify',
                                paddingLeft: '2.5%'
                            }}>
                                <p>With Epic, Travel savings aren’t limited to just one vacation a year. As part of the Epic Vacations
                                family, you have access to exclusive deals on hotels, vacation homes, flights, cars, curated
                                experiences such as river cruises, cooking classes, sightseeing tours, shopping and restaurant
                                discounts, and more.</p>
                                <p>Earn Member Reward Credits on Epic Experiences. Accumulate and redeem valuable Member
                                Reward Credits towards future Epic Experiences.</p>   
                                <p>Share your Epic Experiences benefits with family and friends. Let them enjoy your special
                                member pricing and you receive reward credits when they travel.</p>
                                <p>Learn more about the benefits
                                of Epic Experiences <Link to='/epic-experiences'>here</Link>.</p>
                            </div>
                        </Grid>
                        <Grid item xs={4} style={{ padding: '0px 40px', textAlign: 'center', width: '100%' }}>
                            <img src={image2} alt='vacation' style={{ width: '100%', borderRadius: '6px' }} />
                            <button className="action-button">Epic VIP Hilton Head Island Rental Program</button>
                            <div style={{
                                fontSize: '1.1em',
                                fontFamily: 'Assistant',
                                fontWeight: '500',
                                width: '95%',
                                lineHeight: '150%',
                                textAlign: 'justify',
                                paddingLeft: '2.5%'
                            }}>
                                <p>Experience the ultimate island getaway with the Epic VIP Hilton Head Island Rental Program! As a valued member, get your dream vacation at one of our select Hilton Head Island resorts. Revel in unbeatable weekly rental pricing!</p>
                                <p>
                                    Studios and One-Bedrooms - $500++<br />
                                    Two-Bedrooms - $600++<br />
                                    Two-Bedroom Lofts and Three-Bedrooms - $700++
                                </p>
                                <p>
                                    Don’t miss out on this incredible opportunity to relax in style on Hilton Head Island. Send your requests to <a href="mailto:membercare@myepicvacations.com">membercare@myepicvacations.com</a> and start planning your perfect escape!
                                </p>
                                <p>* Based on availability. 60 Day Advanced Booking Window. Does not include taxes and fees. Program pricing subject to change. Other rules and restrictions may apply.</p>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            }

            {owner && <Footer />}
        </div>
    )
}