import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner_2.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";
import PointsImage from '../images/epic-points-basics.png';

export function EpicExperiences() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', margin: '0px' }}>
                            <img src={banner} style={{ width: '100%' }} alt='beach image' />
                        </div>
                        <div style={{
                            fontSize: '2.5em',
                            fontFamily: 'Satisfy',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            margin: '10px'
                        }}>
                            Epic Experiences
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: '60px' }}>
                    <Grid item xs={12}>
                        <div style={{
                            margin: 'auto',
                            width: '70%',
                            lineHeight: '130%',
                            fontSize: '1.15em',
                            textAlign: 'justify'
                        }}>
                            <div>Your Epic Experiences is where you can find year-round travel savings and benefits on many travel and leisure related items. The higher your membership tier, the more benefits and savings you have access to.</div>
                            <div>
                                <table className='epic-experiences-table'>
                                    <tr>
                                        <th>Discounts</th>
                                        <th className='center'>Traveler</th>
                                        <th className='center'>Wayfarer</th>
                                        <th className='center'>Voyager</th>
                                        <th className='center'>Explorer</th>
                                    </tr>
                                    <tr>
                                        <td>Hotel Stays</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Car Rentals</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Flights</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Cruises</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Activities</td>
                                        <td></td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Homes</td>
                                        <td></td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Weeks</td>
                                        <td></td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Luxury Residences</td>
                                        <td></td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Shopping Rewards</td>
                                        <td></td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Gift Card Reward Redemption</td>
                                        <td></td>
                                        <td></td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Restaurant Savings</td>
                                        <td></td>
                                        <td></td>
                                        <td className='center'>X</td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Wine Club</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className='center'>X</td>
                                    </tr>
                                    <tr>
                                        <td>Personal Concierge</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className='center'>X</td>
                                    </tr>
                                </table>
                            </div>
                            <div className='tier-sub-label'>Reward Credits</div>
                            <div>When booking through your Epic Experiences, you earn reward credits. Redeem reward credits for select Epic Experiences.</div>
                            <div className='tier-sub-label'>110% Best Price Guarantee</div>
                            <div>If you find a better price online for the exact same reservation, Epic Experiences will give you 110% of the difference in reward credits. Find full details by clicking on Epic Experiences Home Page.</div>
                            <div className='tier-sub-label'>Epic Travel Savings</div>
                            <div>Share your discounts and benefits with your family and friends! When they book, you receive reward credits. Sit back and watch your Epic Reward Credits soar! Find full details by clicking on Epic Experiences Home Page.</div>
                            <div className='tier-sub-label'>Epic Experiences Email Offers</div>
                            <div>Make sure to add info@premiummemberservices.com to your e-mail address book to ensure you receive important communications and travel deals.</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            }

            {owner && <Footer />}
        </div>
    )
}