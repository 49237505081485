import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";
import image1 from '../images/image_1.jpg';
import image2 from '../images/image_2.jpg';
import image3 from '../images/image_3.jpg';

export function HiltonHeadIslandPrivileges() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', margin: '0px' }}>
                            <img src={banner} style={{ width: '100%' }} alt='beach image' />
                        </div>
                        <div style={{
                            fontSize: '2.5em',
                            fontFamily: 'Satisfy',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            margin: '10px'
                        }}>
                            Hilton Head Island Privileges
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: '60px' }}>
                    <Grid item xs={12}>
                        <div style={{
                            margin: 'auto',
                            width: '60%',
                            lineHeight: '130%',
                            fontSize: '1.15em',
                            textAlign: 'center'
                        }}>
                            <div className='tier-label'>Epic Home Group Resort</div>
                            <div>Epic Members are the only ones with access to availability for Home Group Resort 11 to 10 months prior to check-in date.</div>
                            <div className='tier-label'>No Reservation Fees</div>
                            <div>Epic Members have no reservation fees when booking one of the participating resorts in the Epic Home Group through RCI, Spinnaker at Shipyard, Village at Palmetto Dunes, or Ocean Cove at Palmetto Dunes.</div>
                            <div className='tier-label'>Member Only VIP Hilton Head Island Access</div>
                            <div>Another way to enjoy your Home Group Resorts is through Member Only VIP rental pricing.</div>
                            <div>To express interest, contact us at: <a href="mailto:membercare@myepicvacations.com?subject=Home Group Resort Rental Inquiry">membercare@myepicvacations.com</a></div>
                            <div className='tier-label'>Hilton Head Island Access</div>
                            <div className='tier-sub-label'>Studios and One Bedroom Villas</div>
                            <div>$500 per week</div>
                            <div className='tier-sub-label'>Two Bedroom Villas and Townhomes</div>
                            <div>$600 per week</div>
                            <div className='tier-sub-label'>Two Bedroom w/ Loft and Three Bedroom Villas</div>
                            <div>$700 per week</div>
                            <div className='tier-footnote'>* Based on availability. Subject to black-out dates and holidays. Valid booking window is 60 days prior to check-in date.<br />Taxes and fees not included. Pricing subject to change at any time.</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            }

            {owner && <Footer />}
        </div>
    )
}