import { Member } from "./interfaces/Member";
import { setCookie } from 'typescript-cookie'
import MemberLoginCredentials from "./interfaces/MemberLoginCredentials";

export async function loginWithPassword(memberLoginCredentials: MemberLoginCredentials, successCallback: ((owner: Member) => void), errorCallback: ((error: any) => any)): Promise<Member | any> {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer IxEe8MDzioXMO2ZzDC1q");
  myHeaders.append("Content-Type", "application/json");

  var requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(memberLoginCredentials)
  };

  fetch(`https://tiki-prod-api.azurewebsites.net/api/Members/MemberLogin/`, requestOptions)
    .then(response => {
      if (!response.ok) {
        errorCallback(response);
      } else {
        return response.json();
      }
    })
    .then((result) => {
      const owner: Member = result;
      owner.communications = '';

      if (owner) {
        setCookie('owner', JSON.stringify(owner));
      }
      successCallback(owner);  
    })
    .catch(error => errorCallback(error));
}

export async function authOwnerWithToken(ownerNumber: string, token: string, successCallback: ((owner: Member) => void), errorCallback: ((error: any) => any)): Promise<Member | any> {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer IxEe8MDzioXMO2ZzDC1q");

  var requestOptions: RequestInit = {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`https://rdpapi.azurewebsites.net/api/owner/2/${ownerNumber}/${token}`, requestOptions)
    .then(response => {
      if (!response.ok) {
        errorCallback(response);
      } else {
        return response.json();
      }
    })
    .then((result) => {
      const owner: Member = result;
      setCookie('username', owner.username);
      setCookie('token', owner.authToken);
      successCallback(result);
    })
    .catch(error => errorCallback(error));
}

export async function partnerLogin(member: Member, successCallback: ((member: Member) => void), errorCallback: ((error: any) => any)): Promise<Member | any> {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer IxEe8MDzioXMO2ZzDC1q");
  myHeaders.append("Content-Type", "application/json");

  var requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(member)
  };

  fetch(`https://tiki-prod-api.azurewebsites.net/api/Members/PartnerLogin/`, requestOptions)
    .then(response => {
      if (!response.ok) {
        errorCallback(response);
      } else {
        return response.json();
      }
    })
    .then((result) => {
      console.log(result);
      const member: Member = result;
      if (member) {
        console.log(member.sorLink);
      }
      successCallback(member);  
    })
    .catch(error => errorCallback(error));
}

export async function requestPasswordReset(email: string, successCallback: (() => void), errorCallback: ((error: any) => any)): Promise<void> {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer IxEe8MDzioXMO2ZzDC1q");
  myHeaders.append("Content-Type", "application/json");

  var requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow'
  };

  fetch(`https://tiki-prod-api.azurewebsites.net/api/Members/ResetPassword/?email=` + email, requestOptions)
    .then(response => {
      if (!response.ok) {
        errorCallback(response);
      } else {
        return response;
      }
    })
    .then((result) => {
      console.log(result);
      successCallback();  
    })
    .catch(error => errorCallback(error));
}

export async function updatePassword(member: Member, successCallback: (() => void), errorCallback: ((error: any) => any)): Promise<void> {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer IxEe8MDzioXMO2ZzDC1q");
  myHeaders.append("Content-Type", "application/json");

  var requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(member)
  };

  fetch(`https://tiki-prod-api.azurewebsites.net/api/Members/UpdateWebsitePassword/`, requestOptions)
    .then(response => {
      if (!response.ok) {
        errorCallback(response);
      } else {
        return response;
      }
    })
    .then((result) => {
      console.log(result);
      successCallback();  
    })
    .catch(error => errorCallback(error));
}
