import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import hero from "../images/home-hero.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";
import logo from '../images/epic-logo.png';

const planVaction = () => {
    window.location.href = '/plan-my-vacation';
}

export function EmbarcHomePage() {
    const [owner, setOwner] = useState<Member>();
    const [sorLink, setSorLink] = useState('');

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
            const member = JSON.parse(ownerCookie) as Member;

            const getSorLink = async () => {
                const memberWithSorLink = await data.partnerLogin(
                    member,
                    (member) => {
                        setSorLink(member.sorLink);
                    },
                    (error) => {
                        console.log(error);
                    })
            }

            getSorLink();

        } else {
            window.location.href = "/login";
        }
    }, [])

    const visitPartner = (url: string) => {
        const partnerModal = document.getElementById('partner-modal') || undefined;
    
        if (partnerModal) {
            partnerModal.style.display = 'block';
    
        }
    
        const partnerLink = document.getElementById('partner-link') as HTMLAnchorElement || undefined;
    
        if (partnerLink) {
            partnerLink.href = url;        
        } else {
            console.log('no partnerLink');
        }
    
    }

    const removeModal = () => {
        const partnerModal = document.getElementById('partner-modal') || undefined;
    
        if (partnerModal) {
            partnerModal.style.display = 'none';
    
        }
    }
        
    return (
        <div style={{
        }}>

        {owner && <Header owner={owner} />}

        {owner &&
                    <div id='partner-modal' style={{ display: 'none' }}>
                    <div style={{ position: 'fixed', top: '0', left: '0', backgroundColor: 'black', opacity: '.6', zIndex: '1', width: '100vw', height: '100vh' }}>
                    </div>
                    <div className="modal-solid">
                        <div className="modal-content-solid">
                            <div style={{ width: '100%', textAlign: 'center' }}><img style={{ width: "30%", margin: '30px 10px 10px 10px' }} src={logo} alt='Epic Logo' /></div>
                            <div style={{ margin: '30px', fontSize: '1.1em' }}>
                                You are about to visit one of our travel partner's website. The partner website will open in a new tab in your browser. To return to the Member Portal, please click on this tab or close the partner website tab.
                            </div>
                            <div style={{ width: '100%', textAlign: 'center' }}>
                                <a id='partner-link' onClick={ removeModal } href='#' style={{ color: 'white' }} target='_blank'><button style={{
                                    margin: '20px 0px',
                                    padding: '10px 20px',
                                    backgroundColor: '#031d42',
                                    width: '50%',
                                    border: '0px solid',
                                    borderRadius: '8px',
                                    color: 'white',
                                    fontFamily: 'Assistant',
                                    fontWeight: '500',
                                    fontSize: '1.2em'
                                }} >Continue</button></a>
                            </div>
                        </div>
                    </div>
                </div>
                }

            {owner &&            
                <Grid container style={{
                    minHeight: '80vh',
                    backgroundImage: `url(${ hero })`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                    width: '100%'
                            }} >
                    <Grid item xs={12} style={{ padding: '20px', minHeight: '30vh' }} >
                        <div style={{ 
                            fontSize: '1.2em', 
                            margin: '20px auto', 
                            width: '50%',
                            backgroundColor: '#f0f0f0',
                            opacity: '0.8',
                            padding: '30px',
                            borderRadius: '20px',
                            }}>
                                <p style={{
                                    fontSize: '2.2em',
                                    fontFamily: 'Satisfy',
                                    fontWeight: 'bold',
                                    width: '100%',
                                    textAlign: 'center',
                                    marginBottom: '10px'
                                }}>
                                    Welcome to Epic Vacations!
                                </p>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontFamily: 'Assistant',
                                    fontWeight: '500',
                                    width: '100%',
                                    textAlign: 'center',
                                    lineHeight: '150%'
                                }}>
                                    Vacation where you choose, when you choose, on your terms.  Enjoy your membership and may all your vacations be Epic!
                                </p>
                        </div>
                        <Grid item xs={12}>
                            <div style={{
                                padding: '15px',
                                width: '100%',
                                textAlign: 'center'
                            }} >
                                <button onClick={() => visitPartner(sorLink)} style={{
                                    margin: '0px 10px',
                                    padding: '15px 25px',
                                    backgroundColor: '#031d42',
                                    border: '0px solid',
                                    borderRadius: '8px',
                                    color: 'white',
                                    fontFamily: 'Assistant',
                                    fontWeight: '500',
                                    fontSize: '1.5em'
                                }} >Plan My Vacation</button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {owner && <Footer />}
        </div>
    )
}