import { Grid } from "@material-ui/core";
import logo from '../images/epic-logo.png';
import hero from '../images/login-hero.jpg';
import "../css/main.css";
import * as data from '../Data'
import { useEffect, useState } from "react";
import { Member } from "../interfaces/Member";
import { getCookie } from "typescript-cookie";

const updatePassword = async () => {
    const updatePasswordButton = document.getElementById('update-password-button') as HTMLButtonElement;
    updatePasswordButton.disabled = true;
    updatePasswordButton.innerText = 'Please wait...';
    removeErrorMessage();

    const newPassword = (document.getElementById('new-password') as HTMLInputElement).value;
    const repeatPassword = (document.getElementById('repeat-password') as HTMLInputElement).value;

    const queryParameters = new URLSearchParams(window.location.search)
    const auth_token = queryParameters.get("auth_token")
    let memberUpdate: any = {};

    if (auth_token) {
        memberUpdate.authToken = auth_token;
        if (newPassword != repeatPassword) {
            addErrorMessage('Your passwords do not match. Please try again.');
            updatePasswordButton.innerText = 'Update Password';
            updatePasswordButton.disabled = false;
        } else {
            memberUpdate.password = newPassword;
            const memberAuth = await data.updatePassword(
                memberUpdate,
                () => {
                    alert("Your password was updated. Please login using your new password.");
                    window.location.href = "/login";
                },
                (error) => {
                    console.log(error);
                    addErrorMessage('There was an issue trying to update your account. Please contact Member Care.');
                    updatePasswordButton.style.display = 'hidden';
                }
            )
        }
    } else {
        addErrorMessage('There was an issue trying to update your account. Please contact Member Care.');
        updatePasswordButton.style.display = 'hidden';

    }


}

const removeErrorMessage = () => {
    let errorMessage = (document.getElementById('error-message') as HTMLInputElement);
    errorMessage.classList.add("hidden");
    errorMessage.innerHTML = '';
}

const addErrorMessage = (message: string) => {
    let errorMessage = (document.getElementById('error-message') as HTMLInputElement);
    errorMessage.classList.remove("hidden");
    errorMessage.innerText = message;
}

export function ResetPassword() {
    return (
        <div>
            <Grid container>
                <div style={{
                    backgroundImage: `url(${hero})`,
                    minHeight: '100vh',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                    width: '100%',
                }}>
                </div>

                <div className="modal-wide">
                    <div className="modal-content">
                        <div style={{ width: '100%', textAlign: 'center' }}><img style={{ width: "50%", margin: '20px 10px 10px 10px' }} src={logo} alt='Epic Logo' /></div>
                        <div className="login-form">
                            <p>Please enter a new password below.</p>
                            <table cellSpacing="8">
                                <tbody>
                                    <tr>
                                        <td style={{ textAlign: 'right' }}>New Password:</td>
                                        <td><input id="new-password" className="login-input" type="password" size={36}></input></td>
                                    </tr>
                                    <tr>
                                        <td style={{ textAlign: 'right' }}>Repeat New Password:</td>
                                        <td><input id="repeat-password" className="login-input" type="password" size={36}></input></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ textAlign: 'center' }}>
                            <button id="update-password-button" onClick={updatePassword} style={{ cursor: 'pointer', margin: '10px 0px 30px 0px', padding: '10px 30px', backgroundColor: '#22a845', border: '0px solid', borderRadius: '5px', color: 'white' }} >Update Password</button>
                        </div>
                        <div id="error-message" className="login-error hidden"></div>
                    </div>
                </div>
            </Grid>
        </div>
    )
}