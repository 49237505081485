import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner_5.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";
import image1 from '../images/image_1.jpg';
import image2 from '../images/image_2.jpg';
import image3 from '../images/image_3.jpg';

export function MemberResources() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', margin: '0px' }}>
                            <img src={banner} style={{ width: '100%' }} alt='beach image' />
                        </div>
                        <div style={{
                            fontSize: '2.5em',
                            fontFamily: 'Satisfy',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            margin: '10px'
                        }}>
                            Member Resources
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: '60px' }}>
                    <Grid item xs={12}>
                        <div style={{
                            margin: 'auto',
                            width: '50%',
                            lineHeight: '130%',
                            fontSize: '1.1em'
                        }}>
                            <div style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '5px', fontSize: '1.2em' }}>Epic Vacations Membership Tiers</div>
                            <div><Link to='/traveler-tier' className='body-link'>Traveler Tier</Link></div>
                            <div><Link to='/wayfarer-tier' className='body-link'>Wayfarer Tier</Link></div>
                            <div><Link to='/voyager-tier' className='body-link'>Voyager Tier</Link></div>
                            <div><Link to='/explorer-tier' className='body-link'>Explorer Tier</Link></div>
                            <div style={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '5px', fontSize: '1.2em' }}>Additional Resources</div>
                            <div><Link to='/epic-points-basics' className='body-link'>Epic Points Basics</Link></div>
                            <div><Link to='/epic-experiences' className='body-link'>Epic Experiences</Link></div>
                            <div><Link to='/membership-dues' className='body-link'>Epic Vacations Membership Dues</Link></div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            }

            {owner && <Footer />}
        </div>
    )
}