import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner_2.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";
import image1 from '../images/image_1.jpg';
import image2 from '../images/image_2.jpg';
import image3 from '../images/image_3.jpg';

export function EpicAdvantagePoints() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', margin: '0px' }}>
                            <img src={banner} style={{ width: '100%' }} alt='beach image' />
                        </div>
                        <div style={{
                            fontSize: '2.5em',
                            fontFamily: 'Satisfy',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            margin: '10px'
                        }}>
                            Epic Advantage Points
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: '60px' }}>
                    <Grid item xs={12}>
                        <div style={{
                            margin: 'auto',
                            width: '70%',
                            textAlign: 'justify',
                            lineHeight: '130%',
                            fontSize: '1.1em'
                        }}>
                            <p>Epic Advantage Points allow you the ability to purchase more points to complete a reservation for a grander vacation, bigger accommodations, exotic location, or more desirable time. Your membership tier determines the additional amount of Epic Advantage Points you can purchase each year. Advantage Points are purchased in 5,000 -point increments at a low member only rate plus transaction fee.</p>
                            <p>Advantage Points expire December 31st of the year in which they were purchased. Your unused Advantage Points will be autosaved one time into the upcoming year. If you have not used any Points the year of expiration, an RCI inactivity fee of $39 applies.</p>
                            <p><b>Two Ways To Purchase Advantage Points:</b></p>
                            <p style={{ marginLeft: '20px'}}>
                                1. Contact your Epic Vacations RCI Guides at (844) 433-5400
                                <br />Monday – Friday (8:30 am – 9:00 pm EST), Saturday (9:00 am – 6:00 pm EST)
                            </p>
                            <p style={{ marginLeft: '20px', fontSize: '0.9em', textDecoration: 'italic' }}>
                                * Points will generally appear in your account within one business day. RCI/Epic Guides are also able to process Advantage Points transactions that complete your reservation at the time of booking.</p>
                            <p style={{ marginLeft: '20px'}}>
                                2. Contact Epic Member Care at 855-869-3742, Option 0 or email membercare@myepicvacations.com
                                <br />Monday – Friday (8:00 am – 5:00 pm EST)
                            </p>
                            <p style={{ marginLeft: '20px', fontSize: '0.9em', textDecoration: 'italic' }}>
                                * Points may take 24-48 hours to appear in your account. Epic Member Services cannot complete a reservation for members.</p>

                            <div>
                                <table className='points-table'>
                                    <tr>
                                        <th>Membership Tier</th>
                                        <th>Epic Annual<br />Base Points</th>
                                        <th>Advantage Points<br />Available For Purchase</th>
                                        <th>Potential Total<br />Annual Points</th>
                                    </tr>
                                    <tr>
                                        <td>Traveler</td>
                                        <td>15,000</td>
                                        <td>35,000</td>
                                        <td>50,000</td>
                                    </tr>
                                    <tr>
                                        <td>Wayfarer</td>
                                        <td>15,000</td>
                                        <td>60,000</td>
                                        <td>75,000</td>
                                    </tr>
                                    <tr>
                                        <td>Voyager</td>
                                        <td>15,000</td>
                                        <td>85,000</td>
                                        <td>100,000</td>
                                    </tr>
                                    <tr>
                                        <td>Explorer</td>
                                        <td>15,000</td>
                                        <td>135,000</td>
                                        <td>150,000</td>
                                    </tr>
                                </table>
                            </div>                            
                        </div>
                    </Grid>
                </Grid>
            </div>
            }

            {owner && <Footer />}
        </div>
    )
}