import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import hero from "../images/home-hero.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";
import slide1 from '../images/slide-1.jpg';
import slide2 from '../images/slide-2.jpg';

const planVaction = () => {
    window.location.href = '/plan-my-vacation';
}

export function HomePage() {
    const [owner, setOwner] = useState<Member>();
    const ownerCookie = getCookie('owner') || undefined;

    useEffect(() => {

        if (ownerCookie) {
            const member = JSON.parse(ownerCookie) as Member;

            if (member.membershipTier == 'Embarc') {
                window.location.href = "/embarc";
            } else {
                setOwner(member);                
            }
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div style={{
        }}>

        {owner && <Header owner={owner} />}

            {owner &&
                <Grid container style={{
                    minHeight: '80vh',
                    backgroundImage: `url(${ hero })`,
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    position: 'relative',
                    width: '100%'
                            }} >
                    <Grid item xs={12} style={{ padding: '20px', minHeight: '30vh' }} >
                        <div style={{ 
                            fontSize: '1.2em', 
                            margin: '20px auto', 
                            width: '50%',
                            backgroundColor: '#f0f0f0',
                            opacity: '0.8',
                            padding: '30px',
                            borderRadius: '20px',
                            }}>
                                <p style={{
                                    fontSize: '2.2em',
                                    fontFamily: 'Satisfy',
                                    fontWeight: 'bold',
                                    width: '100%',
                                    textAlign: 'center',
                                    marginBottom: '10px'
                                }}>
                                    Welcome to Epic Vacations!
                                </p>

                                <p style={{
                                    fontSize: '1.1em',
                                    fontFamily: 'Assistant',
                                    fontWeight: '500',
                                    width: '100%',
                                    textAlign: 'center',
                                    lineHeight: '150%'
                                }}>
                                    Vacation where you choose, when you choose, on your terms.  Enjoy your membership and may all your vacations be Epic!
                                </p>
                        </div>
                        <Grid item xs={12}>
                            <div style={{
                                padding: '15px',
                                width: '100%',
                                textAlign: 'center'
                            }} >
                                <button onClick={ planVaction } style={{
                                    margin: '0px 10px',
                                    padding: '15px 25px',
                                    backgroundColor: '#031d42',
                                    border: '0px solid',
                                    borderRadius: '8px',
                                    color: 'white',
                                    fontFamily: 'Assistant',
                                    fontWeight: '500',
                                    fontSize: '1.5em'
                                }} >Plan My Vacation</button>
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            }

            {owner && <Footer />}
        </div>
    )
}