import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner_6.jpg";
import { getCookie } from 'typescript-cookie'
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";

export function ExplorerTier() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', margin: '0px' }}>
                            <img src={banner} style={{ width: '100%' }} alt='beach image' />
                        </div>
                        <div style={{
                            fontSize: '2.5em',
                            fontFamily: 'Satisfy',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            margin: '10px'
                        }}>Explorer Tier
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: '60px' }}>
                    <Grid item xs={12}>
                        <div style={{
                            margin: 'auto',
                            width: '50%',
                            lineHeight: '130%',
                            fontSize: '1.15em',
                            textAlign: 'center'
                        }}>
                            <div className='tier-label'>Epic Membership Base Points</div>
                            <div>15,000</div>
                            <div className='tier-label'>Epic Advantage Points Available for Purchase</div>
                            <div>135,000</div>
                            <div className='tier-label'>Epic Escapes</div>
                            <div>Condominium vacations with lengths of your choosing.</div>
                            <div>Reservations can be made up to 10 months prior to arrival.</div>
                            <div className='tier-label'>Epic Vacations</div>
                            <div>Full week condominium vacations at your choice of more than 4,300 resorts worldwide</div>
                            <div>Reservations can be made up to 24 months prior to arrival.</div>
                            <div className='tier-label'>Epic Experiences</div>
                            <div className='tier-sub-label'>Year round travel savings and benefits:</div>
                            <div>Hotel Stays</div>
                            <div>Car Rentals</div>
                            <div>Flights</div>
                            <div>Cruises, Yachts, and Sailboats</div>
                            <div>Luxury Vacation Homes</div>
                            <div>Activities such as guided tours, wine tastings, kayak or whitewater rafting, helicopter tours, museum tickets and more!</div>
                            <div>Worldwide Tours</div>
                            <div>Restaurant Savings</div>
                            <div>Wine Club</div>
                            <div className='tier-sub-label'>110% Best Price Guarantee</div>
                            <div>If you find a better price online, you will receive 110% of the difference in reward credits.</div>
                            <div className='tier-sub-label'>Reward Credits</div>
                            <div>Earn Reward Credits when booking through Epic Experiences.</div>
                            <div>Retail Shopping Rewards - Earn Reward Credits when shopping favorite brands through Epic Experiences.</div>
                            <div>Redeem Reward Credits towards hotels, flights, gift cards, restaurant certificates, worldwide tours and more!</div>
                            <div className='tier-sub-label'>Epic Travel Savings</div>
                            <div>Share your savings with friends and family and earn Reward Credits for their bookings.</div>
                            <div className='tier-sub-label'>Personal Concierge Services</div>
                            <div className='tier-label'>Hilton Head Island Privileges</div>
                            <div className='tier-sub-label'>Epic Home Group Resort</div>
                            <div>Epic Members are the only ones to see availability for Home Group Resort 11-10 months prior to check-in date.</div>
                            <div className='tier-sub-label'>No Reservation Fees</div>
                            <div>No reservation fees if you book one of the participating resorts in the Epic Home Group.</div>
                            <div>Spinnaker at Shipyard, Village at Palmetto Dunes, or Ocean Cove at Palmetto Dunes.</div>
                            <div className='tier-sub-label'>VIP Hilton Head Island Access</div>
                            <div>Enjoy additional member only VIP Access to Hilton Head Island Home Resorts.</div>
                            <div>60-day advanced booking window with Epic Member Only pricing.</div>
                            <div className='tier-footnote'>* Revised 03.16.22 Offerings, terms and conditions subject to change.</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            }

            {owner && <Footer />}
        </div>
    )
}