import { Grid } from '@material-ui/core'
import logo from '../images/epic-logo-white.png'

export function Footer() {
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container style={{ color: 'white', minHeight: '35vh' }} >
          <Grid item xs={8} style={{ backgroundColor: '#031d42', paddingTop: '40px', paddingLeft: '120px' }} >
            &nbsp;
          </Grid>
  
          <Grid item xs={4} style={{ backgroundColor: '#031d42', paddingTop: '40px', paddingRight: '20px' }} >
            <div className="footer-right">
              <div><img src={ logo } /></div>
              <div className="FooterCompanyName">Epic Vacations</div>
              <div className="FooterCompanyAddress">Email: membercare@myepicvacations.com</div>
              <div className="FooterCompanyAddress">Phone: (855) 869-3742</div>
            </div>
          </Grid>
  
          <Grid item xs={12} style={{ backgroundColor: '#031d42', textAlign: 'center', fontSize: '.7em', padding: '40px' }}>
            &copy; RMC Resort Management 2022
          </Grid>
        </Grid>
      </Grid>  
    )
}