import { Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import logo from '../images/epic-logo.png';
import { Member } from "../interfaces/Member";

export function Header(props: any) {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid container style={{ width: '100%', minHeight: '80px', backgroundColor: '#f0f0f0', opacity: '0.95', color: 'white' }}>
          <Grid item xs={6}>
            <img src={logo} style={{ height: '80px', padding: '20px' }} />
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right', padding: '25px', color: '#282828', fontWeight: 'bold' }}>
            <div className='header-welcome'>Welcome back, { props.owner.firstName } ({ props.owner.memberId })</div>
            <div>
              <Link to={"/member-profile"}><div style={{ display: 'inline-block', paddingLeft: '20px', paddingTop: '6px', fontSize: '1em', cursor: 'pointer', color: '#031d42' }}>Member Profile</div></Link>
              <Link to={"/login"}><div style={{ display: 'inline-block', paddingLeft: '20px', paddingTop: '6px', fontSize: '1em', cursor: 'pointer', color: '#031d42' }}>Logout</div></Link>
            </div>
          </Grid>
        </Grid>

        {props.owner.membershipTier != 'Embarc' && 
          <Grid container style={{ width: '100%', minHeight: '30px', padding: '10px', backgroundColor: '#031d42' }}>
            <Link to="/"><div className="main-nav-link">Home</div></Link>
            <Link to="/plan-my-vacation"><div className="main-nav-link">Plan My Vacation</div></Link>
            <Link to="/epic-advantage-points"><div className="main-nav-link">Epic Advantage Points</div></Link>
            <Link to="/member-reward-credits"><div className="main-nav-link">Member Award Credits</div></Link>
            <Link to="/share-the-love"><div className="main-nav-link">Share the Love</div></Link>
            <Link to="/hilton-head-island-privileges"><div className="main-nav-link">Hilton Head Island Privileges</div></Link>          
            <Link to="/member-resources"><div className="main-nav-link">Member Resources</div></Link>
            <Link to="/contacts"><div className="main-nav-link">Contacts</div></Link>
          </Grid>
        }

      {props.owner.membershipTier == 'Embarc' && 
          <Grid container style={{ width: '100%', minHeight: '30px', padding: '10px', backgroundColor: '#031d42' }}>
            <Link to="/"><div className="main-nav-link">Home</div></Link>
          </Grid>
        }


      </Grid>
    )
  }
  