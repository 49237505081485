import { Grid } from "@material-ui/core";
import { Footer } from "../components/Footer";
import { Header } from "../components/Header";
import banner from "../images/banner_3.jpg";
import * as data from '../Data'
import { getCookie } from 'typescript-cookie'
import { Link } from "react-router-dom";
import { Member } from "../interfaces/Member";
import { useEffect, useState } from "react";
import PointsImage from '../images/epic-points-basics.png';

export function EpicPointsBasics() {
    const [owner, setOwner] = useState<Member>();

    useEffect(() => {
        const ownerCookie = getCookie('owner') || undefined;

        if (ownerCookie) {
            setOwner(JSON.parse(ownerCookie) as Member);
        } else {
            window.location.href = "/login";
        }
    }, [])

    return (
        <div>

            {owner && <Header owner={owner} />}

            {owner &&
            <div>
                <Grid container>
                    <Grid item xs={12}>
                        <div style={{ width: '100%', margin: '0px' }}>
                            <img src={banner} style={{ width: '100%' }} alt='beach image' />
                        </div>
                        <div style={{
                            fontSize: '2.5em',
                            fontFamily: 'Satisfy',
                            fontWeight: 'bold',
                            width: '100%',
                            textAlign: 'center',
                            margin: '10px'
                        }}>
                            Epic Points Basics
                        </div>
                    </Grid>
                </Grid>
                
                <Grid container style={{ marginBottom: '60px' }}>
                    <Grid item xs={12}>
                        <div style={{
                            margin: 'auto',
                            width: '70%',
                            lineHeight: '130%',
                            fontSize: '1.15em',
                            textAlign: 'justify'
                        }}>
                            <div className='tier-sub-label'>Your Epic Base Member Points</div>
                            <div>Your annual Epic Vacations Base Point Allotment is 15,000 Points. To provide ample time and flexibility, each batch of Base Points expires the year following the year it was allotted. At the end of the Expiration Year (2), any unused points will autosave into the following year (Autosave Year), expiring December 31 s of that Autosave Year. An RCI inactivity fee may apply if you have not used any of the 15,000 Points. At the end of the Autosave Year (3), a member can choose to extend unused Base Points one more year and by paying an extension fee or let the unused points expire.</div>
                            <div style={{ margin: '20px', textAlign: 'center' }}><img src={ PointsImage } alt='Epic Points Basics' /></div>
                            <div>After the initial allotment of Epic Base Points, your annual allotment will be deposited each January for the following use year.</div>
                            <div className='tier-sub-label'>Borrowing Future Years Points</div>
                            <div>To make it as convenient as possible to plan your vacation, we provide the ability for you to borrow up to the total of the following Use Years Points in your account to complete a reservation in the current Use Year.</div>
                            <div className='tier-sub-label'>Points Account Information</div>
                            <div>Access to your points balance and other account information can be attained by choosing Epic Escapes and Vacations. You will then be directed to the RCI/Epic home page. Click on the profile icon on the top right corner. You can track usage, balances, reservations, make payments, and keep your account information up to date here.</div>
                            <div>All Epic Points are governed by RCI’s use and access policies.</div>
                        </div>
                    </Grid>
                </Grid>
            </div>
            }

            {owner && <Footer />}
        </div>
    )
}